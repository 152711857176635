<template>
<div>

	<div v-for="category in categorys" :key="category.category_id">
		<div class="text-h5">{{category.category_name}}</div>
		<div class="mb-5">
			<v-simple-table class="plant-list-table">
				<colgroup>
				<col style="width:20px" />
				<col style="width:120px" />
				<col style="width:100px" />
				<col style="width:700px" />
				</colgroup>
				<tr>
					<th>No</th>
					<th>日付</th>
					<th>形式</th>
					<th>ダウンロード・リンク名称</th>
				</tr>
				<tr v-for="(siryo , index) in filterCategory(category.category_id)" :key="siryo.siryo_id">
					<td>{{index+1}}</td>
					<td>{{dateFormat(siryo.open_date)}}</td>
					<td>
						<template v-if="siryo.siryo_kbn===1">ファイル</template>
						<template v-if="siryo.siryo_kbn===2">リンク</template>
					</td>
					<td>
						<template v-if="siryo.siryo_kbn===1">
							<v-btn style="vertical-align:baseline" text @click="clickDownload(siryo)" class="post-link">
							{{siryo.siryo_title}}
							</v-btn>
						</template>
						<template v-if="siryo.siryo_kbn===2">
							<v-btn
								style="vertical-align:baseline"
								:href="siryo.siryo_url"
								target="_blank"
							 text  class="post-link">
							{{siryo.siryo_title}}
							</v-btn>
						</template>
					</td>
				</tr>
			</v-simple-table>
		</div>


	</div>



</div>
</template>
<style lang="scss" scoped>
.post-link {
  color:#1976d2;
  display: inline-block;
  word-break: break-all;
  padding: 2px 8px;
  border-radius: 5px;
  text-transform: none !important;
  white-space: normal;
  max-width:500px; 
  height: auto !important;
}
</style>
<script>
import {CONSTANT} from '../constant';
export default {
	data() {
		return {
			categorys:[],
			siryos:[],
			orgSiryos:[],
			tabValue:0,		
		}
	},
	mounted(){
		console.log("mount")
		this.getSiryo();
	},
	methods: {
        getSiryo(){
            this.axios.post("/member/siryo/get-siryo-list",{disp_type:'2'}).then((res)=>{
                if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
					this.categorys = res.data.resultData.categorys;
					this.orgSiryos = res.data.resultData.siryos;
					this.filterSiryo(this.tabValue);
                }
            })
        },
		sortData(data){
			return data.sort((a,b) => {
				if(a.open_date === b.open_date)
					return 0;
				return a.open_date > b.open_date? -1 : 1
			});
		},
		filterCategory(category_id){
			console.log('id=' + category_id)
			const arr =  this.orgSiryos.filter(function(item){
				return item.category_id == category_id;
			});
			console.log(arr)
			this.sortData(arr);
			return arr;
		},
		/**
		 * タブの切替イベント
		 * @param {タブのID} tabNo 
		 */
		changeTab(tabNo){
			console.log("chgtab=" + tabNo)
			this.filterSiryo(tabNo);
		},
		/**
		 *タブ切り替え時のリスト表示処理 
		 * @param {タブ番号} tabNo 
		 */
		filterSiryo(tabNo){
			this.siryos = [];

			const category_id = this.categorys[tabNo].category_id;
			this.siryos = this.orgSiryos.filter(function(item){
				return item.category_id == category_id;
			})
		},
		/**
		 * ダウンロードボタンクリック 
		 * @param {資料情報} siryo 
		 */
		async clickDownload(siryo){
			const res = await this.axios.post('/member/siryo/get-siryo-file',{siryo_id:siryo.siryo_id});
			if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
				const blob = this.toBlob(res.data.resultData.siryo_base64_file, res.data.resultData.siryo_file_mime_type);
				const fileName = res.data.resultData.siryo_dl_name;
				var link = document.createElement('a');
				document.body.appendChild(link);
				link.href = URL.createObjectURL(blob);
				link.target = '_blank';
				link.download = fileName; 
				link.click();
				document.body.removeChild(link);

			}
		},
    	toBlob(data64, mime_ctype) {
			var bin = atob(data64.replace(/^.*,/, ''));
			var buffer = new Uint8Array(bin.length);
			for (var i = 0; i < bin.length; i++) {
				buffer[i] = bin.charCodeAt(i);
			}
			// Blobを作成
			try {
				var blob = new Blob([buffer.buffer], {
					type: mime_ctype,
				});
			} catch (e) {
				return false;
			}
			return blob;
    	},

		
	},
}
</script>