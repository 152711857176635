<template>
  <v-app>
    <!-- <Menu /> -->
    <Header/>
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
// import Menu from "./components/Menu"
import Header from "./components/Header"
import Footer from "./components/Footer"
export default {
  components:{Header, Footer},
  methods : {
    setMeta(route){
      // タイトルを設定
      if(route.meta.title){
        let setTitle = route.meta.title;
        document.title = setTitle;
      }
      // ディスクリプションを設定
      if(route.meta.desc){
        let setDesc = route.meta.desc;
        document.querySelector("meta[name='description']").setAttribute('content', setDesc)
      }
    }
  },
  mounted(){
    let route = this.$route;
    this.setMeta(route);
  },
  watch: { 
    '$route' (route, from) {
      this.setMeta(route);
    }
  }
};
</script>
