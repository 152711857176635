<template>
    <v-app-bar class="header-title" color="white" app clipped-left>
        <v-app-bar-nav-icon @click="chgShowMenu"></v-app-bar-nav-icon>
        <a href="https://jasra.or.jp/index.html"><v-toolbar-title class="text-h4 web-container-title"></v-toolbar-title></a>
        <div class="ma-9" v-if="this.$route.name!='Siryo'">
            <v-tabs @change="chgTab" v-model="tabNo">
                <v-tab>プラントマップ</v-tab>
                <v-tab>プラント受入可否・種類・料金情報</v-tab>
                <v-tab>プラント保有重機情報</v-tab>
            </v-tabs>
        </div>
        <v-spacer></v-spacer>
    </v-app-bar>
</template>
<style lang="scss" scoped>
.header-title {
    //background: #eee !important;
}
.header-title,
.header-title .v-btn {
    color: #555 !important;
}
.web-container-title {
    height: 42px;
    width: 300px;
    background: url("/img/logo.png") no-repeat left top/contain;
    image-rendering: -webkit-optimize-contrast;
}
</style>
<script>
import { MESSAGE } from "../messages";
export default {
    name: "HeaderBar",
    data: () => ({
        drawer: null,
        tabNo: 0,
        items: [
            {
                text: "プラントマップ",
                disabled: false,
                href: "/",
            },
            {
                text: "プラント情報（保有重機情報）",
                disabled: false,
                href: "/jukilist",
            },
            {
                text: "プラント情報（利用料金・稼働情報）",
                disabled: true,
                href: "/kadolist",
            },
        ],
    }),
    computed: {
        isLogin() {
            return this.$store.state.token == "" ? false : true;
        },
    },
    mounted() {
        switch (this.$route.name) {
            case "JukiList":
                this.tabNo = 2;
                break;
            case "KadoList":
                this.tabNo = 1;
                break;
            default:
                this.tabNo = 0;
        }
    },
    methods: {
        chgTab(val) {
            // if(!this.$store.state.initFlag){
            //   this.$store.commit('setInitFlag',true);
            //   switch(this.$route.name){
            //     case 'Top':
            //         this.tabNo = 0;
            //         break;
            //     case 'JyukiList':
            //         this.tabNo = 1;
            //     case 'KadoList':
            //         this.tabNo = 2;
            //         break;
            //     default:
            //         this.tabNo = 0;
            //   }
            //   return ;
            // }
            switch (val) {
                case 0:
                    if (this.$route.name !== "Top") {
                        this.$router.push({ name: "Top" });
                    }
                    this.tabNo = 0;
                    break;
                case 2:
                    this.$router.push({ name: "JukiList" });
                    this.tabNo = 2;
                    break;
                case 1:
                    this.$router.push({ name: "KadoList" });
                    this.tabNo = 1;
                    break;
            }
        },
        chgShowMenu() {
            this.$store.commit("chgShowMenu");
        },
        async logout() {
            const res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R004, "ログオフ")
            );

            if (!res) {
                return;
            }

            axios.post("/api/logout");
            this.$store.commit("setToken", "");
            this.$router.push("/login");
        },
    },
};
</script>
