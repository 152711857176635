<template>
  <div class="d-flex justify-center">
    <div class="a" >
      <h1 class="text-center my-5">お知らせや資料のダウンロード</h1>
      <siryo-download></siryo-download> 
    </div>
  </div>
</template>
<style lang="scss" scoped>
</style>
<script>
  import SiryoDownload from '../components/SiryoDownload.vue';
  export default {
    name: 'Home',

    components: {
      SiryoDownload
    },

    data () {
      return {
     
      }
    },
    mounted(){
    },
    methods: {
    }
  }
</script>
