<template>
  <div class="d-flex justify-center">
    <div class="a" >
      <h1 class="text-center my-5">プラント発生土受入可否・改良土種類・料金情報</h1>
      <div class="d-flex justify-end">
        <div class="d-flex d-inline-flex align-center my-3">
          <span>絞り込み条件</span>
          <v-select
              class="mx-2"
              label="支部"
              v-model="selShibu"
              :items="shibu"
              item-text="shibu_name"
              item-value="shibu_cd"
              outlined
              dense
              hide-details=""
              persistent-hint
              style="width:200px;"
              @change="changeShibu"
              return-object
          ></v-select>
          <v-select
              label="都道府県"
              v-model="selTdfk"
              :items="dispTdfk"
              item-text="tdfk_name"
              item-value="tdfk_cd"
              outlined
              dense
              hide-details=""
              persistent-hint
              style="width:200px;"
              @change="changeTdfk"
              return-object
          ></v-select>
        </div>
      </div>

      <v-simple-table
        fixed-header
        height="500px"
        class="kadotable"
      >
        <template v-slot:default>
          <col style="width:200px" />
          <col style="width:250px" />
          <col style="width:120px" />
          <col style="width:120px" />
          <col style="width:150px" />
          <col style="width:150px" />
          <col style="width:100px" />
          <col style="width:100px" />
          <col style="width:100px" />
          <col style="width:100px" />
          <col style="width:110px" />
          <col style="width:100px" />
          <thead>
            <tr>
              <th rowspan="2" class="grey lighten-3">
                プラント名称
              </th>
              <th rowspan="2" class="grey lighten-3">
                所在地<br/>
                電話番号
              </th>
              <th rowspan="2" class="grey lighten-3">
                プラント<br>
                認証番号
              </th>
              <th rowspan="2" class="grey lighten-3">
                営業日<br>
                営業時間
              </th>
              <th rowspan="2" class="grey lighten-3">
                改良土の種類
              </th>
              <th rowspan="2" class="grey lighten-3">
                原料土<br>
                受入土質
              </th>
              <th colspan="2" class="grey lighten-3">
                料金（税込み）・改良土品質
              </th>
              <th colspan="3" class="blue lighten-4">
                最新情報
              </th>
              <th rowspan="2" class="grey lighten-3">
              </th>
            </tr>
            <tr>
              <th class="grey lighten-3">
                原料土
              </th>
              <th class="grey lighten-3">
                改良土
              </th>
              <th class="blue lighten-4">
                原料土<br>
                受入可能量
              </th>
              <th class="blue lighten-4">
                改良土<br>
                ストック量
              </th>
              <th class="blue lighten-4">
                データ更新<br>
                日時
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in plantList"
              :key="item.name"
            >
              <td>{{ item.plant_name }}</td>
              <td>{{ item.address3 }}
                  <br />
                  <span v-if="item.tel">連絡先：{{ item.tel }}</span>
              </td>
              <td>{{ item.actec_no_pre }} {{ item.actec_no }}</td>
              <td>
                {{ item.sales_day }}<br>
                {{ item.sales_time }}
              </td>
              <td>{{ item.hp_kaido_name }}</td>
              <td>{{ item.hp_gendo_uke_name }}</td>
              <td class="t-r"><div v-if="item.hp_gendo_uke_kingaku>0">{{ numberFormat(item.hp_gendo_uke_kingaku) }}<span class="text-caption pl-1">円</span></div></td>
              <td class="t-r"><div v-if="item.hp_kaido_kingaku>0">{{ numberFormat(item.hp_kaido_kingaku) }}<span class="text-caption pl-1">円</span></div></td>
              <td class="t-r">{{ numberFormat(item.now_gendo_uke) }}<span class="text-caption pl-1">m3</span></td>
              <td class="t-r">{{ numberFormat(item.now_kaido_stock) }}<span class="text-caption pl-1">m3</span></td>
              <td class="t-c">{{ item.last_upddate }}</td>
              <td>
                <v-btn
                  color="primary"
                  @click="clickPlantDetail(item.plant_id)"
                >詳細</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  <!-- <dlg-plant-detail ref="refDlgPlant"></dlg-plant-detail> -->
  <dlg-plant-detail
            ref="refDlgPlantDetail"
            :call-dlg-contact="callDlgContact"
        ></dlg-plant-detail>
  <dlg-plant-contact ref="refDlgPlantContact"></dlg-plant-contact>
  </div>
</template>
<style lang="scss" scoped>
.t-r{
  text-align: right;
}
.t-c{
  text-align: center;
}
.plant-header{
  background-color: grey;
}
.v-simple-table th {
  background-color: red;
}
.kadotable table{
  min-width: 1000px !important;
}
.kadotable table{
  table-layout: fixed;
}
.kadotable table th{
  padding:5px !important;
  text-align: center !important;;
}
.kadotable table tbody td {
  border: solid 1px #ddd !important;
  border-collapse: collapse;
  padding:5px !important;
}
.v-data-table--fixed-header .v-data-table__wrapper table thead{
  position: sticky !important;
  top: 0;
  z-index: 2;
}
.v-data-table--fixed-header .v-data-table__wrapper table thead tr th{
  position: unset;
  border-right: solid 1px #ccc9c9 !important;
}
.v-data-table--fixed-header .v-data-table__wrapper table thead tr:first-child th{
  border-top: solid 1px #ccc9c9 !important;
  border-right: solid 1px #ccc9c9 !important;
}
.v-data-table--fixed-header .v-data-table__wrapper table thead tr:first-child th:first-child{
  border-left: solid 1px #ccc9c9 !important;
  border-right: solid 1px #ccc9c9 !important;
}
</style>
<script>
  import DlgPlantDetail from '../components/dialog/DlgPlantDetail.vue';
  import DlgPlantContact from "../components/dialog/DlgPlantContact.vue";
  export default {
    name: 'Home',

    components: { DlgPlantDetail, DlgPlantContact },
    data () {
      return {
        //search condtion
        selShibu: '',
        shibu:[],
        selTdfk: '',
        tdfk:[],
        dispTdfk:[],

        // list data
        plantHeaders: [
          { text: 'プラント名称', value: 'plant_name', class: 'plant-header' },
          { text: '所在地', value: 'address3' },
          { text: '電話番号', value: 'tel' },
          { text: "プラント認証番号", value: 'tel' },
          { text: '', value: 'detail' },
        ],
        plantList:[],
      }
    },
    mounted(){
      this.getMstData();
      this.getPlantList();
    },
    methods: {
      changeShibu() {
        if(this.selShibu.shibu_cd == ''){
          this.dispTdfk = this.tdfk;
          this.selTdfk = {tdfk_cd: ''};
        }else{
          this.dispTdfk = this.tdfk.filter( item => item.shibu_cd == this.selShibu.shibu_cd );
          this.dispTdfk.unshift({ tdfk_name: '全て', tdfk_cd: '' });
          this.selTdfk = { tdfk_name: '全て', tdfk_cd: '' };
        }
        this.getPlantList();
      },
      changeTdfk() {
        this.getPlantList();
      },
      async getPlantList() {
            const postData = {
                shibu_id: this.selShibu.shibu_cd,
                tdfk_cd: this.selTdfk.tdfk_cd,
            }
            await this.axios.post('/plant-search', postData)
            .then((res) => {
                console.log(res)
                this.plantList = res.data.resultData.plants;
            })
      },
      getMstData() {
         this.axios.get('general/get-mstdata')
            .then((res) => {
                console.log(res)
                this.shibu = res.data.resultData.shibu;
                this.shibu.unshift({ shibu_name: '全て', shibu_cd: '' });

                this.tdfk = res.data.resultData.tdfk;
                this.tdfk.unshift({ tdfk_name: '全て', tdfk_cd: '' });
                this.dispTdfk = this.tdfk;
            });
      },
      // clickDetail(plantId){
      //   this.$refs.refDlgPlant.open(plantId);
      // },
      clickPlantDetail(plantId) {
            this.$refs.refDlgPlantDetail.open(plantId);
        },
      callDlgContact() {
            this.$refs.refDlgPlantContact.open();
        },
    },
  }
</script>
