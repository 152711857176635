<template>
	<v-dialog v-model="dialog" persistent max-width="700px">
		<v-card>
			<v-toolbar color="primary" height="40" dark>
				<v-toolbar-title>お問合せ</v-toolbar-title>
				<v-spacer />
			</v-toolbar>
			<v-container v-if="$vuetify.breakpoint.xs">
				<div class="basic-area px-2 mt-5">
					<v-list>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>会社名</v-list-item-subtitle>
							<v-list-item-title class="">
								<v-text-field
									class="my-1"
									type="text"
									dense
									outlined
									hide-details=""
									persistent-hint
								></v-text-field>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>ご担当者様名</v-list-item-subtitle>
							<v-list-item-title class="">
								<v-text-field
									class="my-1"
									type="tel"
									dense
									outlined
									hide-details=""
									persistent-hint
								></v-text-field>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>ご連絡先（メール）</v-list-item-subtitle>
							<v-list-item-title class="">
								<v-text-field
									class="my-1"
									type="tel"
									dense
									outlined
									hide-details=""
									persistent-hint
								></v-text-field>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>ご連絡先（電話）</v-list-item-subtitle>
							<v-list-item-title class="">
								<v-text-field
									class="my-1"
									type="tel"
									dense
									outlined
									hide-details=""
									persistent-hint
								></v-text-field>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>お問合せ内容</v-list-item-subtitle>
							<v-list-item-title class="">
								<v-textarea
									class="my-1"
									dense
									outlined
									hide-details=""
									persistent-hint
									rows="3"
								></v-textarea>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					</v-list>
					<div class="btns-area mx-2 my-5">
						<v-btn class="mr-2 btn-close" @click="dialog=false">閉じる</v-btn>
						<v-btn @click="sendMail" class="btn-save">送信する</v-btn>
					</div>
				</div>
			</v-container>
			<v-container v-else>
				<div class="basic-area px-10 mt-5">
					<table class="plant-list-table">
						<col style="width: 200px" />
						<col style="width: auto" />
						<tr>
							<th class="text-left pa-2">お問い合わせ先</th>
							<td>
								<p class="ma-0 text-caption blue-grey--text">会社名</p>
								<p class="mb-2">{{plantInfo.member_name}}</p>
								<p class="ma-0 text-caption blue-grey--text">プラント名</p>
								<p class="mb-2">{{plantInfo.plant_name}}</p>
							</td>
						</tr>
						<tr>
							<th class="text-left pa-2">
								<span >会社名</span>
								<span class="text-caption red--text font-weight-bold">（必須）</span>
							</th>
							<td class="pl-1"><v-text-field
								class="my-1"
								v-model="companyName"
								autofocus
								dense

								:readonly="chkflg"
								:outlined="!chkflg"
								hide-details="auto"
								:error-messages="err.companyName"
								></v-text-field>
							</td>
						</tr>
						<tr>
							<th class="text-left pa-2">
								<span>ご担当者名</span>
								<span class="text-caption red--text font-weight-bold">（必須）</span>
								</th>
							<td class="pl-1"><v-text-field
								class="my-1"
								v-model="tantouName"
								dense
								:readonly="chkflg"
								:outlined="!chkflg"
								hide-details="auto"
								:error-messages="err.tantouName"
								></v-text-field>
							</td>
						</tr>
						<tr>
							<th class="text-left pa-2">ご連絡先（メール）
								<span class="text-caption red--text font-weight-bold">（必須）</span>
							</th>
							<td class="pl-1"><v-text-field
								class="my-1"
								type="tel"
								v-model="mailaddress"
								dense
								:readonly="chkflg"
								:outlined="!chkflg"
								hide-details="auto"
								:error-messages="err.mailaddress"
								></v-text-field>
							</td>
						</tr>
						<tr>
							<th class="text-left">ご連絡先（電話）</th>
							<td class="pl-1"><v-text-field
								class="my-1"
								type="tel"
								v-model="tel"
								dense
								:readonly="chkflg"
								:outlined="!chkflg"
								hide-details="auto"
								:error-messages="err.tel"
								></v-text-field>
							</td>
						</tr>
						<tr>
							<th class="text-left" style="height:200px;">お問合せ内容
								<span class="text-caption red--text font-weight-bold">（必須）</span>
							</th>
							<td>
								<v-textarea
									class="my-1"
									dense
									:outlined="!chkflg"
									:readonly="chkflg"
									hide-details="auto"
									v-model="content"
									:error-messages="err.content"
									rows="6"
								></v-textarea>
							</td>
						</tr>
					</table>
				</div>
				<div class="ma-10">
					<template v-if="!chkflg">
						<div class="d-flex justify-end">
						<v-btn class="mr-2 btn-close" @click="dialog=false">閉じる</v-btn>
						<v-btn @click="clickNext" class="btn-save">次へ</v-btn>
						</div>

					</template>
					<template v-else>
						<div class="text-caption red--text mb-3">お問い合わせ内容が正しいければ、「送信する」ボタンをクリックしてください</div>
						<v-spacer/>
						<div class="d-flex justify-end">
						<v-btn class="mr-2 btn-close" @click="clickBack">戻る</v-btn>
						<v-btn @click="sendMail" class="btn-save">送信する</v-btn>
						</div>
					</template>
				</div>
			</v-container>
		</v-card>
	</v-dialog>
</template>
<style scoped>
    .btns-area {
	    display:flex;
	    justify-content: right;
	}
</style>
<script>
import {mapState} from 'vuex'
// import RyokinFile from "../../components/RyokinFile.vue"
import { MESSAGE } from "../../messages";
import {CONSTANT} from "../../constant";

export default {
    // components: { RyokinFile },
    props:{
    },
    data: () => ({
		chkflg:false,
		plantInfo:[],
        dialog: false,
		companyName:'',
		tantouName:'',
		mailaddress:'',
		tel:'',
		content:'',
		err:{
			companyName:'',
			tantouName:'',
			mailaddress:'',
			tel:'',
			content:'',
		}
    }),
    mounted(){
		console.log()
    },
    methods: {
        open() {
			this.clearError()
			this.chkflg = false;
			this.companyName = '';
			this.tantouName='';
			this.mailaddress='';
			this.tel='';
			this.content='';

			this.plantInfo = this.$store.state.contactPlantInfo;
            this.dialog = true;
        },
		getPlatInfo(){

		},
		clickNext(){
			//エラーをクリア
			this.clearError()
			if(this.inputCheck()){
				this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);	
				return ;
			}
			this.chkflg = true;
		},
		/**
		 * 入力チェック
		 */
		inputCheck(){
			// 会社名
			if(this.companyName ===''){
				this.err.companyName = [MESSAGE.formatByArr(MESSAGE.E001,'会社名')]
			}
			//　担当者名
			if(this.tantouName ===''){
				this.err.tantouName = [MESSAGE.formatByArr(MESSAGE.E001,'ご担当者名')]
			}
			//　メールアドレス必須　
			if(this.mailaddress ===''){
				this.err.mailaddress = [MESSAGE.formatByArr(MESSAGE.E001,'メールアドレス')]
			}else{
				if(!this.isMailaddress(this.mailaddress)){
					this.err.mailaddress = [MESSAGE.formatByArr(MESSAGE.E005,'メールアドレス')]
				}
			}
			if(this.content == ''){
				this.err.content = [MESSAGE.formatByArr(MESSAGE.E001, 'お問合わせ内容')]
			}
            for (let key in this.err) {
                if (this.err[key].length > 0){
                    return true;
                }
            }
            return false;
		},
		/**
		 * 送信ボタン押下
		 */
		async sendMail() {
	       // 確認メッセージ
            let res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.R005);
            if(!res){
                this.saveLoading = null;
                return ;
            }

			const sendRes = await this.axios.post(`/general/sent-mail-to-plant`,{
				plant_id: this.plantInfo.plant_id,
				company_name: this.companyName,
				tantou_name:this.tantouName,
				mailaddress:this.mailaddress,
				tel:this.tel,
				content:this.content,
			})

			this.swalToast( MESSAGE.I005);
                 this.dialog = false;

			//this.chkflg = true;
			//console.log('send')
		},
		clickBack(){
			this.chkflg = false;
		},
        clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
        },

    },
};
</script>
