import moment from "moment"

export default {
    methods: {
        /*****************************************
         * 配列が存在するかを確認する
         * @param {エラー配列} val 
         * @returns 
         ****************************************/
        isError: function (val) {
            if (val == undefined)
                return false;
            if (!Array.isArray(val))
                return false;
            if (val.length > 0) {
                return true;
            }
            return false;
        },
        /*****************************************
         * エラーメッセージ表示編集
         * コントロール下に表示するエラーメッセージのフォーマット
         ****************************************/
        dispError: function (val) {
            var err = '';
            for (var key in val) {
                err = err + `<p class="ma-0">${val[key]}</p>`
            }
            return err;
        },
        /*****************************************
         * 郵便番号チェック 
         * @param {郵便番号} str 
         * @returns 
         ****************************************/
        isPostCode: function (str) {
            if ( str.match(/^\d{3}-?\d{4}$/) ) {
                return true;
            } 
            return false;
        },
        /*****************************************
         * メールアドレスチェック 
         * @param {文字列}} ymd 
         * @returns 
         ****************************************/
        isMailaddress: function (str) {
            str = (str == null) ? "" : str;
            if (str.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
                return true;
            } else {
                return false;
            }
        },
        /*****************************************
         * 電話番号チェック 
         * @param {文字列}} ymd 
         * @returns 
         ****************************************/
        isTelNo: function (str) {
            str = (str == null) ? "" : str;
            if (str.match(/^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/)) {
                return true;
            } else {
                return false;
            }
        },
        /*****************************************
         * 半角英数チェック 
         * @param {文字列}} ymd 
         * @returns 
         ****************************************/
        isHanEisu: function (str) {
            str = (str == null) ? "" : str;
            if (str.match(/^[A-Za-z0-9]*$/)) {
                return true;
            } else {
                return false;
            }
        },
        /*****************************************
         * 半角英数チェック(記号有り) 
         * @param {文字列}} ymd 
         * @returns 
         ****************************************/
         isHanEisuKigo: function (str) {
            str = (str == null) ? "" : str;
            if (str.match(/^[A-Za-z0-9!#&]*$/)) {
                return true;
            } else {
                return false;
            }
        },
        /*****************************************
         * 日付のフォーマット 
         * @param {日付} ymd 
         * @returns 
         ****************************************/
        dateFormat: function (ymd) {
            if (ymd === null || !moment(ymd).isValid) {
                return '';
            }

            const date = moment(ymd);
            return date.format('YYYY-MM-DD');
        },
        dateFromToCheck: function (from, to) {

            if (to === null || !moment(to).isValid) {
                return true;
            }
            if (from === null || !moment(from).isValid) {
                return true;
            }
            const toDate = moment(to);
            const fromDate = moment(from);
            if (fromDate > toDate) {
                return false;
            }
            return true;
        },
        /**
         * 数値カンマ編集
         */
        numberFormat: function (price) {
            if(price===undefined)
                return "";
            return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
        },
        /**
         * コンテナコードの編集
         */
        contCodeFormat: function (contId) {

            var ptn = /^[0-9]+$/;
            if (ptn.test(contId)) {
                return ('0000000' + contId).slice(-7);
            } else {
                return contId;
            }
        },
        /**
         * 担当者コードの編集
         */
        TantouCodeFormat: function (tantouCd) {

            var ptn = /^[0-9]+$/;
            if (ptn.test(tantouCd)) {
                return ('00000' + tantouCd).slice(-5);
            } else {
                return tantouCd;
            }
        },
        /**
         * Confirm メッセージ表示
         * 「はい」「いいえ」ボタン
         * @param {タイトル} title 
         * @param {メッセージ内容} message 
         * @returns true:はい
         *          false:いいえ 
         */
        swalConfirm: function (title, message) {
            return this.$swal({
                title: title,
                text: message,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "はい",
                cancelButtonText: "いいえ"
            }).then((result) => {
                if (result.isConfirmed) {
                    /**はい */
                    return true;
                }
                /**いいえ */
                return false;
            })
        },
        /**
         * アラートメッセージ表示 
         * @param {*} title 
         * @param {*} message 
         * @param {*} icon 
         * @returns 
         */
        swalAlert: function (title, message, icon = "error") {
            return this.$swal({
                title: title,
                text: message,
                icon: icon
            })
        },
        /**
         * トーストメッセージ 
         * @param {*} title 
         * @param {*} icon 
         */
        swalToast: function (title, icon = "success") {
            this.$swal({
                text: title,
                toast: true,
                icon: icon,
                showConfirmButton: false,
                position: 'bottom-end', timer: 3000
            })
        },
        /*****************************************
         * 緯度・経度チェック
         * @param {緯度/経度} latlng 
         * @returns 
         ****************************************/
         latlngFormat: function (latlng) {
            latlng = typeof latlng == 'string'? latlng:String(latlng);

            if (latlng.match(/^[-]?[0-9\.]*$/) && latlng > -1000 && latlng < 1000) {
                return false;
            } else {
                return true;
            }
        },
    }
};